
<template>
  <div>
    <!-- Header Background Image -->
    <!-- Sign Up Image And Headings -->
    <div class="sign-up-header" style="background-image: url('images/folib/banner.jpg')">
      <div class="content">
        <h1 class="mb-5">FOLIB 漏洞库</h1>
        <p class="text-lg">本漏洞库专为国产制品库folib以及开源治理服务foeyes提供数据服务.</p>
      </div>
    </div>

    <!-- / Header Background Image -->

    <!-- User Profile Card -->
    <a-card :bordered="false" class="card-profile-head">
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12" class="col-info">
            <a-avatar :size="74" shape="square"  style="border-radius: 8px; background-image: linear-gradient( 310deg, #141727, #3a416f ); margin: 8px" src="images/folib/fovuln.svg" />
            <div class="avatar-info">
              <h4 class="font-semibold m-0">FoVuln</h4>
              <p>数据源于包含NVC、CNVD等多种数据源的集合，经过精准校验后的商用数据源</p>
            </div>
          </a-col>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
            <a-radio-group v-model="profileHeaderBtns" @change="filterData" size="small">
              <a-radio-button value=1 >CNNVD数据</a-radio-button>
              <a-radio-button value=0>所有数据</a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
    </a-card>

    <!-- Orders List card -->
    <a-card :bordered="false" class="header-solid mb-24 card-profile-list" :bodyStyle="{padding: '16px', paddingTop: '16px'}">
      <!-- Table search -->
      <div class="mx-25">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :md="16" class="text-left">
            <a-input-search placeholder="输入编号或名称关键字进行搜索" size="small" style="max-width: 300px; margin-right: 12px;" v-model="queryData.searchText" @change="getPageList" />
            <a-input-search placeholder="输入年份进行搜索" size="small" style="max-width: 300px;" v-model="queryData.year" @change="getPageList" />
          </a-col>
          <a-col :span="24" :md="8" style="display: flex; align-items: center; justify-content: flex-end">
            <a-pagination v-model="queryData.page" :total="totalCount" :show-total="totalCount => `共 ${totalCount} 个`"
                          :page-size="queryData.size" @change="onShowSizeChange" size="small">
            </a-pagination>
          </a-col>
        </a-row>
      </div>
      <!-- / Table search -->
      <!-- Orders table -->
      <a-table class="mt-20"
               :columns="columns"
               :data-source="dataList"
               :pagination="false"
               rowKey="vulnId"
               @change="handleTableChange"
      >
        <template slot="vulnId" slot-scope="vulnId, row">
          <a @click="handleGoDetail(row)">{{vulnId}}</a>
        </template>

        <template slot="cwes" slot-scope="cwes">
          <a-tooltip placement="top" v-if="cwes.length>0">
            <template slot="title">
              <span v-for="n in cwes">{{ n.zhName }}</span>
            </template>
            <a-tag v-if="cwes.length>0"  color="purple" v-for="n in cwes" style="white-space: break-spaces;">{{ n.cweId==='NVD-CWE-noinfo'||n.cweId==='NVD-CWE-Other'?'N/A': n.cweId}}</a-tag>
          </a-tooltip>
          <a-tag v-else>N/A</a-tag>
        </template>

        <template slot="baseSeverity" slot-scope="baseSeverity">
<!--          <div class="table-avatar-info">-->
<!--            <a-avatar v-if="baseSeverity" shape="circle" :size="24" src="images/team-2.jpg" />-->
<!--            <a-avatar v-else shape="circle" :size="24">{{ 'A'.slice(0, 1) }}</a-avatar>-->
<!--            <div class="avatar-info">-->
<!--              <p class="mb-0 text-dark">{{ baseSeverity }}</p>-->
<!--            </div>-->
<!--          </div>-->

          <div class="table-avatar-info">
            <a-avatar v-if="baseSeverity === 'HIGH'" shape="circle" :size="24" src="images/folib/high.svg" />
            <a-avatar v-if="baseSeverity === 'MEDIUM'" shape="circle" :size="24" src="images/folib/medium.svg" />
            <a-avatar v-if="baseSeverity === 'CRITICAL'" shape="circle" :size="24" src="images/folib/critical.svg" />
            <a-avatar v-if="baseSeverity === 'LOW'" shape="circle" :size="24" src="images/folib/low.svg" />
            <a-avatar v-if="!baseSeverity" shape="circle" :size="24">N</a-avatar>
            <div class="avatar-info">
              <p  v-if="!baseSeverity" class="mb-0 text-dark">N/A</p>
              <p v-if="baseSeverity" class="mb-0 text-dark">{{ baseSeverity}}</p>
            </div>
          </div>
        </template>

        <template slot="baseScore" slot-scope="baseScore">

          <a-tag v-if="baseScore>=9.0"  color="purple">{{ baseScore }}</a-tag>
          <a-tag v-if="baseScore<9.0&&baseScore>=7.0"  color="red">{{ baseScore }}</a-tag>
          <a-tag v-if="baseScore<7.0&&baseScore>=4.0"  color="orange">{{ baseScore }}</a-tag>
          <a-tag v-if="baseScore<4.0&&baseScore>0"  color="blue">{{ baseScore }}</a-tag>
          <a-tag v-if="!baseScore">N/A</a-tag>
        </template>

        <template slot="descriptionData" slot-scope="descriptionData">{{ JSON.parse(descriptionData)[0].zhValue?JSON.parse(descriptionData)[0].zhValue:JSON.parse(descriptionData)[0].value}}</template>
<!--        <template slot="editBtn" slot-scope="row">-->
<!--          <a-button type="link" :data-id="row.vulnId" class="btn-edit">-->
<!--            Edit-->
<!--          </a-button>-->
<!--        </template>-->

      </a-table>
      <!-- / Orders table -->

    </a-card>
    <!-- / Orders List card -->

  </div>

</template>

<script>

// Sorting function for string attibutes.
import {getVulnerabilitiesList} from "@/api/vulnerabilities";

let stringSorter = function(a, b, attr) {
  if (a[attr] < b[attr])
    return -1;
  if ( a[attr] > b[attr])
    return 1;
  return 0;
}

// Table columns
const columns = [
  {
    title: 'CVE编号',
    dataIndex: 'vulnId',
    sorter: true,
    // sorter: (a, b) => stringSorter(a, b, 'vulnId'),
    sortDirections: ['descend', 'ascend'],
    scopedSlots: { customRender: 'vulnId' },
    width: 200,
    ellipsis: true
  },
  {
    title: '漏洞名称',
    dataIndex: 'descriptionData',
    sorter: true,
    // sorter: (a, b) => stringSorter(a, b, 'descriptionData'),
    sortDirections: ['descend', 'ascend'],
    scopedSlots: { customRender: 'descriptionData' },
    minWidth: 600,
    ellipsis: true
  },
  {
    title: '漏洞类型',
    dataIndex: 'cwes',
    sortDirections: ['descend', 'ascend'],
    scopedSlots: { customRender: 'cwes' },
    width: 150,
  },
  {
    title: '披露时间',
    dataIndex: 'publishedDate',
    sorter: true,
    // sorter: (a, b) => stringSorter(a, b, 'publishedDate'),
    sortDirections: ['descend', 'ascend'],
    width: 200,
  },
  {
    title: '漏洞等级',
    dataIndex: 'baseSeverity',
    sorter: true,
    // sorter: (a, b) => stringSorter(a, b, 'baseSeverity'),
    sortDirections: ['descend', 'ascend'],
    scopedSlots: { customRender: 'baseSeverity' },
    width: 150,
  },
  {
    title: '漏洞评分',
    dataIndex: 'baseScore',
    sorter: true,
    // sorter: (a, b) => stringSorter(a, b, 'baseScore'),
    sortDirections: ['descend', 'ascend'],
    scopedSlots: { customRender: 'baseScore' },
    width: 150,
  }
];


export default {
  data() {
    return {
      profileHeaderBtns: false,
      // Table columns
      columns,

      // Table rows
      dataList: [],


      // Table search query
      query: '',
      totalPages:0,
      totalCount:0,
      queryData: {
        page:1,
        size:20,
        searchText: '',
        year: '',
        orderBy: '',
        orderDirection: '',
        isCnIdQuery: null
      },

      // Table's selected rows
      selectedRowKeys: [],

    }
  },
  computed: {

    // CSV data array
    csvData() {
      return this.data.map(item => ({
        "Id": "%23" + item.key,
        "Date": item.date,
        "Status": item.status,
        "Customer": item.customer.name,
        "Product": item.product,
        "Revenue": "$" + item.revenue,
      }));
    }

  },
  created() {
    this.getPageList()
  },
  methods: {
    handleGoDetail(row) {
      const routeUrl = this.$router.resolve(`/vuln/${row.source}/${row.vulnId}`)
      window.open(routeUrl.href, '_blank');
    },
    handleTableChange(pagination, filters, sorter) {
      this.queryData.orderBy = sorter.columnKey
      this.queryData.orderDirection = sorter.order ? (sorter.order === 'descend' ? 'DESC' : 'ASC') : ''
      this.queryData.page = 1
      this.getPageList()
    },
    getPageList(){
      getVulnerabilitiesList(this.queryData).then(res =>{
        this.dataList=res.data.data
        this.totalCount=res.data.totalCount
        this.totalPages=res.data.totalPages
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.page = current;
      this.queryData.size = pageSize
      this.getPageList()
    },

    // Event listener for input change on table search field.
    onSearchChange() {
      console.log()
      if( this.query.length > 0 ) {
        this.data = data.filter( (row) => {
          for( const key in row ) {
            if( row[ key ]
                .toString()
                .toLowerCase()
                .includes( this.query.trim().toLowerCase() ) )
              return true;
          }
          return false;
        }) ;
      }
      else {
        this.data = data ;
      }
    },

    filterData(){
      console.log(this.profileHeaderBtns)
      if(this.profileHeaderBtns==1){
        this.queryData.isCnIdQuery=true
        this.getPageList()
      }else if(this.profileHeaderBtns==0) {
        delete this.queryData.isCnIdQuery
        this.getPageList()
      }
    },

    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    }

  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
</style>
